
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"bg.jpg"} className="App-logo" alt="logo" />
        <p>
         JOYEUX ANNIVERSAIRE GROS BG VA
        </p>
      </header>
    </div>
  );
}

export default App;
